import { v4 as uuid } from "uuid";
import { authorities } from "@monorepo/utils/src/authorities/authorities";
import { fields } from "./fields";
import { standardMask } from "@monorepo/utils/src/directives/standardMask";
import { cloneDeep } from "lodash";

export const modalElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Заголовок",
    tooltip: "Заголовок описи дел",
    className: "info-modal__element_title",
    value: fields.INVENTORY_TITLE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "№ описи дел",
    tooltip: "Номер описи дел в ОИК",
    className: "info-modal__element",
    value: fields.INVENTORY_NUMBER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Год",
    tooltip: "Год за который сформирована опись дел",
    className: "info-modal__element",
    value: fields.INVENTORY_YEAR,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ОИК",
    tooltip: "Краткое наименование ОИК",
    isLink: true,
    linkRights: [authorities.OIK_LIST],
    className: "info-modal__element_title",
    value: fields.INVENTORY_OIK,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Фонд",
    tooltip: "Номер фонда",
    isLink: true,
    linkRights: [authorities.FUND_LIST],
    className: "info-modal__element",
    value: fields.INVENTORY_FUND,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Вид",
    tooltip: "Вид описи дел может принимать значения Больше или равно 10 лет, Постоянно, По личному составу",
    className: "info-modal__element",
    value: fields.INVENTORY_CATEGORY,
    isShowContentTooltip: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Тип",
    tooltip: "Тип описи дел может принимать значения Передача документов, Изменение сроков хранения документов",
    className: "info-modal__element",
    value: fields.INVENTORY_KIND,
    isShowContentTooltip: true,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата утверждения",
    tooltip: "Дата утверждения описи дел",
    className: "info-modal__element",
    value: fields.INVENTORY_APPROVE_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата поступления",
    tooltip: "Дата поступления описи дел в ЦХЭД",
    className: "info-modal__element",
    value: fields.INVENTORY_ADD_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Статус",
    tooltip: "Статус описи дел может принимать значения: Утверждена, Удалена",
    className: "info-modal__element",
    value: fields.INVENTORY_STATUS,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Заголовок проекта описи дел",
    tooltip: "Заголовок проекта описи дел",
    className: "info-modal__element_title",
    value: fields.INVENTORY_PROTOCOL_HEADER,
    isLink: true,
    linkRights: [authorities.EXPERT_COMMISSION_LIST],
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Тип описи",
    tooltip: "Тип описи может принимать значения: Опись бумажных дел, Опись ЭАД",
    className: "info-modal__element_title",
    value: fields.INVENTORY_TYPE,
  },
  {
    id: uuid(),
    isEdited: true,
    title: "Комментарий",
    tooltip: "Комментарий",
    className: "info-modal__element_full-size info-modal__element_textarea",
    value: fields.COMMENT,
    fieldType: "textarea",
    maxLength: "20000",
    mask: cloneDeep(standardMask.maskNotStartWithSpace),
  },
];

export const mrpElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Номер доверенности",
    tooltip: "Номер доверенности",
    className: "info-modal__element_title",
    value: "mrdPowerAttorneyNumber",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата доверенности",
    tooltip: "Дата доверенности",
    className: "info-modal__element",
    value: "mrdCreationDate",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Срок действия",
    tooltip: "Срок действия",
    className: "info-modal__element",
    value: "mrdExpiredAt",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Наименование",
    tooltip: "Наименование доверителя",
    className: "info-modal__element_title",
    value: "mrdTrusteeCompanyName",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ИНН",
    tooltip: "ИНН доверителя",
    className: "info-modal__element",
    value: "mrdTrusteeInn",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ОГРН",
    tooltip: "ОГРН доверителя",
    className: "info-modal__element",
    value: "mrdTrusteeOgrn",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ФИО лица, действующего без доверенности",
    tooltip: "ФИО лица, действующего без доверенности",
    className: "info-modal__element_title",
    value: "mrdTrusteeFio",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "ФИО представителя",
    tooltip: "ФИО представителя",
    className: "info-modal__element_title",
    value: "mrdRepresentativeFio",
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Полномочия представителя по доверенности",
    tooltip: "Полномочия представителя по доверенности",
    className: "info-modal__element_title",
    value: "mrdPermissions",
    isShowContentTooltip: true,
  },
];

export const techElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Пользователь, изменивший запись",
    className: "info-modal__element_title",
    value: fields.COMMENT_UPDATE_USER,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата изменения",
    className: "info-modal__element_title",
    value: fields.COMMENT_UPDATE_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    className: "info-modal__element_title",
    value: fields.INVENTORY_ID,
  },
];

export const historicalNoteElements = [
  {
    id: uuid(),
    isEdited: false,
    title: "Идентификатор",
    tooltip: "Идентификатор",
    className: "info-modal__element",
    value: fields.NOTE_GUID,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Код фонда",
    tooltip: "Код фонда",
    className: "info-modal__element",
    value: fields.NOTE_FUND_CODE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Дата создания",
    tooltip: "Дата создания",
    className: "info-modal__element",
    value: fields.NOTE_CREATE_DATE,
  },
  {
    id: uuid(),
    isEdited: false,
    title: "Наименование",
    tooltip: "Наименование",
    className: "fund-info-modal__historical-reference info-modal__element_full-size info-modal__element_textarea",
    value: fields.NOTE_TITLE,
    type: "textarea",
  },
];

export const fullModalElements = [...modalElements, ...techElements, ...historicalNoteElements];
