import { fields } from "@monorepo/utils/src/variables/projectsData/ERCProtocolsView/fields";

export const currentFilters = (): Record<string, unknown> => ({
  [fields.INVENTORY_NUMBER]: {},
  [fields.FILTER_OIK]: {},
  [fields.FILTER_INVENTORY_ADD_DATE_FROM]: "",
  [fields.FILTER_INVENTORY_ADD_DATE_TO]: "",
  [fields.FILTER_FUND]: {},
  [fields.FILTER_YEAR]: "",
  [fields.FILTER_STATUS]: {},
  [fields.INVENTORY_TITLE]: "",
  [fields.FILTER_CATEGORY]: [],
  [fields.FILTER_KIND]: [],
  [fields.FILTER_TYPE]: [],
});

export const currentFiltersTop = (): Record<string, unknown> => ({
  [fields.FILTER_KEYWORD]: null,
  [fields.FILTER_SEARCH_MORPH]: false,
  [fields.FILTER_SEARCH_NGRAMED]: false,
  [fields.FILTER_SEARCH_SYNONYM]: false,
  [fields.FILTER_SEARCH_FREQUENCY]: false,
});
