import { IHistoricalNoteElement } from "../../HistoricalNotesView/types/element";
import { IApiERCProtocolsElement, IERCProtocolsElement } from "../types/ERCProtocolsElement";
import moment from "moment";

export const convertApiItemToUi = (
  item: IApiERCProtocolsElement,
  protocolInfo: {
    header: string;
    id: string;
    guid: string;
    fundCode: string;
    createDateTime: string;
    title: string;
    historicalNoteList?: IHistoricalNoteElement[];
  }
): IERCProtocolsElement & {
  inventoryProtocolHeader: string;
  inventoryProtocolId: string;
  historicalNoteList: IHistoricalNoteElement[];
} => ({
  ...item,
  inventoryNumber: item.number || "",
  inventoryYear: item.year || "",
  inventoryStatus: item.status,
  inventoryArchive: item.archive?.name || "",
  inventoryFund: item.fund?.number || "",
  inventoryTitle: item.header || "",
  createDate: item.createDate ? moment(item.createDate).format("YYYY-MM-DD HH:mm") : "",
  date: item.date ? moment(item.date).format("YYYY-MM-DD") : "",
  noteGuid: protocolInfo?.guid || "",
  noteFundCode: protocolInfo?.fundCode ?? "",
  notePeriod: item.historicalNote?.period ? moment(item.historicalNote.period).format("YYYY-MM-DD") : "",
  noteCreateDate: protocolInfo?.createDateTime ? moment(protocolInfo.createDateTime).format("YYYY-MM-DD") : "",
  noteTitle: protocolInfo?.title || "",
  noteId: item.historicalNote?.id || 0,
  oikShortName: item.oik?.name || "",
  comment: item.comment || "",
  inventoryProtocolHeader: protocolInfo?.header || "",
  inventoryProtocolId: protocolInfo?.id || "",

  commentUpdateDate: item.commentUpdateDate ? moment(item.commentUpdateDate).format("YYYY-MM-DD HH:mm") : "",
  commentUpdateUser: item.commentUpdateUser || "",
  highlights: item.highlightFields,
  mrdPowerAttorneyNumber: item.mrdDto?.powerAttorneyNumber || "",
  mrdCreationDate: item.mrdDto?.creationDate ? moment(item.mrdDto.creationDate).format("YYYY-MM-DD HH:mm") : "",
  mrdExpiredAt: item.mrdDto?.expiredAt ? moment(item.mrdDto.expiredAt).format("YYYY-MM-DD HH:mm") : "",
  mrdTrusteeCompanyName: item.mrdDto?.trusteeCompanyName || "",
  mrdTrusteeInn: item.mrdDto?.trusteeInn || "",
  mrdTrusteeOgrn: item.mrdDto?.trusteeOgrn || "",
  mrdTrusteeFio: item.mrdDto?.trusteeFio || "",
  mrdRepresentativeFio: item.mrdDto?.representativeFio || "",
  mrdPermissions: item.mrdDto?.permissionText || "",
  kind: item.kind?.title || "",
  type: item.type?.title || "",
  category: item.category?.title || "",
  historicalNoteList: protocolInfo?.historicalNoteList ?? [],
});
